import Home from './Components/Pages/HomePage/Home'
import About from './Components/Pages/AboutPage/About';
import Mine from './Components/Pages/MinePage/Mine';
import Product1 from './Components/Pages/ProductPage/MicaInfusedQuartz'
import Product2 from './Components/Pages/ProductPage/Quartz'
import Product3 from './Components/Pages/ProductPage/Mica'
import Product4 from './Components/Pages/ProductPage/Feldspar'
import Testreport from './Components/Pages/TestReport/Testreport'
import Gallery from './Components/Pages/Gallery/Gallery';
import ArticlePage from './Components/Pages/ArticlePage/GuidePage'
import { useEffect } from 'react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Report from './Components/Pages/TestReport/Testreport'
import './App.css';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/Home",
    element: <Home/>,
  },
  {
    path: "/About",
    element: <About/>,
  },
  {
    path: "/Mine",
    element: <Mine/>,
  },
  {
    path: "/MicaInfusedQuartz",
    element: <Product1/>,
  },
  {
    path: "/Quartz",
    element: <Product2/>,
  },
  {
    path: "/Mica",
    element: <Product3/>,
  },
  {
    path: "/Feldspar",
    element: <Product4/>,
  },
  {
    path: "/Testreport",
    element: <Testreport/>,
  },
  {
    path: "/Gallery",
    element: <Gallery/>,
  },
  {
    path:"/Article",
    element:<ArticlePage/>
  }
  
]);

function App() {
  useEffect(()=>{
   
},[])
  return (
    <>
     <RouterProvider router={router} />
    </>
  );
}


export default App;


