import React, { useEffect, useState, useRef } from 'react';
// import chatbox from '../Home img/Group 1000001485.webp'
// import chatbox2 from '../Home img/Frame 34584.webp'
import emailjs from '@emailjs/browser';

import './Chatbox.css'


const chatbox = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712065/Koushikmines%20images/Group_1000001485_jxld7h.webp ",
chatbox2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712045/Koushikmines%20images/Frame_34584_r28gla.webp "

const ChatboxComponent = () => {
    const [userInput, setUserInput] = useState('');
    const [chatboxVisible, setChatboxVisible] = useState(false);

    const toggleChatbox = () => {
        setChatboxVisible(!chatboxVisible);
    };

    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        var serviceId = "service_pzfl7lf";
        var templateId = "template_xg5ofxp";
        var publicKey = "cHkn6PIxw3ntDdO_Y"

        var userInputValue = userInput;
        var templateParams = {
            userInput: userInputValue,
        };

        try{
        await emailjs.send(serviceId, templateId, templateParams, publicKey)
             alert('Email sent succesfully! ', + userInputValue);
                setUserInput('');
        }

            catch(error)  {
                console.error('Error sending mail:', error);
                alert('Error sending email. Please try again later.');
            }
    };


    return (
        <div>
          <section>
                <>
                    <div id="fixed-icon" onClick={toggleChatbox}>
                        <img src={chatbox} alt="Message Icon" className="img-fluid"  title='' />
                    </div>

                    <div id="chatbox" style={{ display: chatboxVisible ? 'block' : 'none', backgroundColor: '#f5f5f5' }} >
                        <div className="chatbox">
                            <div className="chat-container">
                                <img
                                    src={chatbox2}
                                    alt="Live Image"
                                    title='' 
                                    style={{
                                        width: '100%',
                                        maxHeight: '100px',
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                        marginBottom: '10px',
                                    }}
                                />
                                <h2 style={{ fontWeight: 500, fontFamily: 'Poppins', textAlign: 'center', padding: '20px', marginTop: '20px' }}>
                                    Please send your Contact and Mail-Id with Query
                                </h2>
                                <div id="chatbox_content">
                                    <h5 className="chat-log" id="chatlog"></h5>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <textarea
                                        id="userInput"
                                        placeholder="Type your message..."
                                        value={userInput}
                                        onChange={(e) => setUserInput(e.target.value)}

                                    ></textarea>
                                    <button className="sendbutton" >
                                        Submit Query
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>

            </section>
        </div>
    )

}

export default ChatboxComponent;