import React, { useEffect, useState } from 'react';
import './About.css'
import { useParams } from 'react-router-dom';


// import Asec1img1 from '../../Home img/about1img.webp'
// import line from '../../Home img/Line 12.webp'
// import Asec3img1 from '../../Home img/Group 1000001498.webp'
// import Asec3img2 from '../../Home img/Group 1000001484.png'
// import vline from '../../Home img/Line 15.png'
// import vector from '../../Home img/Vector.png'
// import mailimg from '../../Home img/Icon.png'
// import phimg from '../../Home img/Icon(1).png'
// import buildingimg from '../../Home img/heroicons_building-office-2.png'
// import minelocimg from '../../Home img/nellore map.webp'
// import officelocimg from '../../Home img/office map.webp'


import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'


const Asec1img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711911/Koushikmines%20images/about1img_evqqtb.webp ",
line = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712217/Koushikmines%20images/Line_12_r1zvsi.webp",
vline = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712220/Koushikmines%20images/Line_15_eyclnn.png",
Asec3img1 ="https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712068/Koushikmines%20images/Group_1000001498_xcjlwe.webp",
Asec3img2= "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712060/Koushikmines%20images/Group_1000001484_tiflu2.png",
vector = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712265/Koushikmines%20images/Vector_nirtcw.png",
mailimg = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712125/Koushikmines%20images/Icon_zu4wnm.png",
phimg = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712127/Koushikmines%20images/Icon_1_dhcv3g.png",
buildingimg= "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712123/Koushikmines%20images/heroicons_building-office-2_kwabyf.png",
minelocimg ="https://peteye-drive.s3.ap-south-1.amazonaws.com/KMM-+img/image.png",
officelocimg = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712245/Koushikmines%20images/office_map_qtzsgd.webp"




export default function About() {
    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div>
            <section>
                <Header/>
            </section>
            <section className='Asec1'>
                <div className='imgsec1'>
                    <img src={Asec1img1} alt='img1' title='' />
                </div>
            </section>

            <section className='Asec2'>
                <div className='Asec2-header'>
                    <h4>About us</h4>
                    <p>We KOUSHIK MINES & MINERALS one of the leading mining company in India and we have our own mines in India to mine different Minerals. All our mines were well developed by deploying Heavy Machinery and providing infrastructure facilities to the workers at mine site. Hence the target production of the minerals (Quartz, Feldspar, Mica Infused Quartz) can be achieved to meet the requirements of the mineral industries.</p>
                    <h3>EQUIPMENT</h3>
                    <p>The mining machinery like Kobelco, Volvo, L & T Komatsu excavators, Compressors, Jock Hammers, Wire Saw, Man Tippers , Wagon Drills, dozer were deployed in our mines. We have also the processing plants for up gradation of the minerals of Quartz, Feldspar & Mica.</p>
                </div>
            </section>

            <section >
                <div className='Asec3'>
                    <h3>Mine Global Distribution</h3>
                    <div className='sec3-div2'>
                        <h5>Our customers are from </h5>
                        <img src={line} alt='line' title=''  />
                    </div>
                    <img className='mapimg' src={Asec3img1} alt='mapimg'  title='' />
                    <img className='blackmineimg' src={Asec3img2} alt='blackimg'  title='' />
                </div>
            </section>

            <section className='Asec4'>
                <h3> Founders & Key Shareholders</h3>
                <div className='minesname'>
                    <p>Koushik Mines <br /> & Minerals</p>
                    <img src={vline} alt='img'  title='' />
                    <p>Sri Bhagavan   <br /> Mines </p>
                </div>
            </section>

            <section className='Asec5'>
                <div className='part1'>
                    <div className='location'>
                        <img src={vector} alt='img'  title='' />
                        <a>Mine Location</a>
                    </div>
                    <p>Survey No. 326/1 , Orupalle Village, Turimerla Post, <br /> Sydapuram Mandal, Nellore District, Andhra Pradesh - <br/>524469, India.</p>
                    <div className='mail'>
                        <img src={mailimg} alt='mailimg' title='' />
                        <a href="mailto:info@koushikmines.com">info@koushikmines.com </a>
                    </div>
                    <div className='mail'>
                        <img src={phimg} alt='phimg' title='' />
                        <a href="https://wa.me/+919246351219">+91 9246351219, </a>
                        <a href="https://wa.me/+919246351219">+91(40)66661269, </a>
                        <a href="https://wa.me/+919246351219">+91(40)66661249 </a>
                    </div>
                </div>
                <div className='part1img'>
                    <a href="https://maps.app.goo.gl/kLwY5Kw1j6aJDbLn9" target="_blank">
                        <img src={minelocimg} alt='img1' title='' />
                    </a>
                </div>
            </section>

            <section className='part1line'>
                <img src={line} alt='img' title='' />
            </section>

            <section className='Asec5'>
                <div className='part1'>
                    <div className='location'>
                        <img src={buildingimg} alt='img' title=''  />
                        <a>Office Location</a>
                    </div>
                    <p>4th Floor, Arunodaya Apartments, #3-6-200/403, Street <br />
                        Number 15, Himayatnagar, Hyderabad, Telangana  <br />
                        500029,India </p>
                    <div className='mail'>
                        <img src={mailimg} alt='mailimg' title='' />
                        <a href="mailto:info@koushikmines.com">info@koushikmines.com </a>
                    </div>
                    <div className='mail'>
                        <img src={phimg} alt='phimg' title='' />
                        <a href="https://wa.me/+919246351219">+91 9246351219 </a>
                    </div>
                </div>
                <div className='part1img'>
                    <a href="https://maps.app.goo.gl/X49fFW4h3WL9MCRS8" target="_blank">
                        <img src={officelocimg} alt='img1' title='' />
                    </a>
                </div>
            </section>
            
            <section>
                <Footer/>
            </section>
            <section>
                <Chatbox/>
            </section>
            
        </div>
    )
}
