import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './Gallery.css'
import Modal from 'react-modal';


// import Gimg1 from "../../Home img/IMG_6262.JPG"
// import Gvid1 from "../../Home img/IMG_6263.MP4"
// import Gimg2 from "../../Home img/IMG_6264.JPG"
// import Gvid2 from "../../Home img/IMG_6265.MP4"
// import Gimg3 from "../../Home img/IMG_6266.JPG"
// import Gvid3 from "../../Home img/IMG_6267.MP4"


import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'
Modal.setAppElement('#root');


const Gimg1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712193/Koushikmines%20images/IMG_6262_kphpva.jpg ",
Gvid1 = "https://res.cloudinary.com/dlmg2jocp/video/upload/v1714712197/Koushikmines%20images/IMG_6263_ktehdg.mp4",
Gimg2 =" https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712198/Koushikmines%20images/IMG_6264_rqdazu.jpg",
Gvid2= "https://res.cloudinary.com/dlmg2jocp/video/upload/v1714712201/Koushikmines%20images/IMG_6265_k7ttah.mp4",
Gimg3 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712203/Koushikmines%20images/IMG_6266_toodys.jpg ",
Gvid3 =" https://res.cloudinary.com/dlmg2jocp/video/upload/v1714712211/Koushikmines%20images/IMG_6267_ytjoml.mp4"

const VideoPopup = ({ src, onClose }) => {
    return (
        <div className="video-popup-overlay" onClick={onClose}>
            <div className="video-popup">
                <video controls autoPlay>
                    <source src={src} type="video/mp4"  title='KMM-video'/>
                </video>
            </div>
        </div>
    );
};

export default function Gallery() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div>
            <section>
                <Header />
            </section>
            <section>
                <h1 className='gallery'>Gallery</h1>

                <div>
                    <div className='Gsec1'>
                        <img className='Gsec1-img1' src={Gimg2} alt='img' title='' />
                        <div  onClick={openPopup}>
                            <video autoPlay muted loop className='Gsec1-vid1'>
                                <source src={Gvid2} type="video/mp4"  title='KMM-video' />
                            </video>
                        </div>
                        {isPopupOpen && <VideoPopup src={Gvid2} onClose={closePopup} />}
                    </div>
                    <div className='Gsec1'>
                        <img className='Gsec1-img1' src={Gimg3} alt='img' title='' />
                        <div  onClick={openPopup}>
                            <video autoPlay muted loop className='Gsec1-vid1'>
                                <source src={Gvid3} type="video/mp4"  title='KMM-video'/>
                            </video>
                        </div>
                        {isPopupOpen && <VideoPopup src={Gvid3} onClose={closePopup} />}
                    </div>
                    <div className='Gsec1'>
                        <img className='Gsec1-img1' src={Gimg1} alt='img'  title='' />
                        <div  onClick={openPopup}>
                            <video autoPlay muted loop className='Gsec1-vid1'>
                                <source src={Gvid1} type="video/mp4" title='KMM-video' />
                            </video>
                        </div>
                        {isPopupOpen && <VideoPopup src={Gvid1} onClose={closePopup} />}
                    </div>
                </div>


            </section>
            <section>
                <Footer />
            </section>
            <section>
                <Chatbox />
            </section>
        </div>
    )
};