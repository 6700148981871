import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import './Product1.css'


// import Psec1img1 from '../../Home img/Group 1000001598.webp'
// import Psec2img1 from '../../Home img/Mask group.webp'
// import Psec2img2 from '../../Home img/IMG-20240116-WA0031.webp'
// import Psec2img3 from '../../Home img/Mask group1.png'
// import Psec3img1 from '../../Home img/7c897555-7c23-46eb-ad44-e2affd3f13c0 1.png'
// import Psec3img2 from '../../Home img/69ae7eaf-6e8c-4f8d-8286-355560f0dbab 1.png'
// import Psec3img3 from '../../Home img/5cf8afb1-67f3-43b2-b7ef-d3060f13e8fc 1.png'
// import Psec3img4 from '../../Home img/8d75b418-779c-4a3f-bd41-6a7e343e874a 1.png'
// import Psec4img1 from '../../Home img/Group 1000001597.webp'
// import Psec4img2 from '../../Home img/Group 1000001588.webp'
// import Psec4img3 from '../../Home img/Group 1000001597(1).webp'

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'


const Psec1img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712114/Koushikmines%20images/Group_1000001598_ytcemg.webp ",
Psec2img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712222/Koushikmines%20images/Mask_group_rgnqah.webp",
Psec2img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712182/Koushikmines%20images/IMG-20240116-WA0031_ez15xw.webp ",
Psec2img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712226/Koushikmines%20images/Mask_group1_pfvp4p.png",
Psec3img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711893/Koushikmines%20images/7c897555-7c23-46eb-ad44-e2affd3f13c0_1_xlaf3r.png",
Psec3img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711902/Koushikmines%20images/69ae7eaf-6e8c-4f8d-8286-355560f0dbab_1_zosqyg.png ",
Psec3img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711890/Koushikmines%20images/5cf8afb1-67f3-43b2-b7ef-d3060f13e8fc_1_xa2j7z.png",
Psec3img4 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711897/Koushikmines%20images/8d75b418-779c-4a3f-bd41-6a7e343e874a_1_fjxbmd.png",
Psec4img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712109/Koushikmines%20images/Group_1000001597_knczvj.webp",
Psec4img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712093/Koushikmines%20images/Group_1000001588_u77vwm.webp",
Psec4img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712112/Koushikmines%20images/Group_1000001597_1_ohsfci.webp"


export default function Product4() {
    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);


    return (
        <div>
            <section>
                <Header/>
            </section>

            <section>
                <div className='Psec1'>
                    <h4>
                        Premium Grade FELDSPAR
                    </h4>
                    <img src={Psec1img1} alt='img' title=''/>
                </div>
            </section>

            <section className='Psec2'>
                <div className='Psec2-header'>
                    <h6>About Feldspar</h6>
                    <p>Feldspar, a mineral as diverse as nature itself, plays an unsung hero in our daily lives. From the elegant glaze on your coffee mug to the sleek glass of your smartphone screen, this mineral is the secret ingredient that brings a touch of gloss to our everyday objects. Its vibrant spectrum, ranging from the subtle hues of moonstone to the dazzling iridescence of labradorite, captures the imagination of jewelers and designers alike. In the realm of industry, feldspar's unique properties make it indispensable in the production of glass and ceramics, offering both the resilience and pliability needed in these crafts. It's not just a mineral; it's a cornerstone of modern aesthetics and functionality.</p>
                    <p>Beyond its visible charm, feldspar tells a story of Earth’s history, its presence in igneous, metamorphic, and sedimentary rocks charting the course of geological transformations. It even extends its narrative beyond our planet, with its discovery in lunar and Martian rocks hinting at the mysteries of the cosmos. Feldspar is more than just a component of the Earth's crust; it's a symbol of the endless interplay between beauty and science, a testament to the intricate dance of natural processes that shape our world and inspire our technological advancements. In every glossy surface and sparkling gem, feldspar whispers a tale of Earth's legacy and human innovation, inviting us to explore the depths of both our planet and our creativity.</p>
                    <div className='roundimg'>
                        <img src={Psec2img1} alt='img' title=''/>
                        <img src={Psec2img2} alt='img' title=''/>
                        <img src={Psec2img3} alt='img' title=''/>
                    </div>

                    <h2>Uses of Feldspar</h2>
                    <div className='Psecimg'>
                        <img src={Psec3img1} alt='img' title=''/>
                        <h5> <strong>Ceramics Production:</strong> Mica-infused quartz enhances semiconductor performance, improving insulation and stability in electronic components and devices.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={Psec3img2} alt='img' title=''/>
                        <h5><strong>Fillers and Extenders:</strong> Mica-infused quartz enhances solar panels by improving light absorption and durability, boosting their overall efficiency.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={Psec3img3} alt='img' title=''/>
                        <h5><strong>Gemstones and Decorative Stone:</strong> Mica-infused quartz crucibles enhance thermal stability, ideal for high-temperature materials processing, melting and this are also used in Semi conductor Vapor
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={Psec3img4} alt='img' title=''/>
                        <h5><strong>Glassmaking:</strong> Processing of silicon, metals, and rare earth elements produces smartphones, computers, televisions, and other electronic devices.
                        </h5>
                    </div>

                </div>
            </section>

            <section className='Psec4'>
                <h3>Enquire</h3>

                <Carousel showThumbs={false} showStatus={false} showIndicators={true} className='Psec4-carousel' autoPlay={true} infiniteLoop={true} dynamicHeight={true}>
                    <div>
                        <img src={Psec4img1} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={Psec4img2} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={Psec4img3} alt='img' title=''/>
                    </div>

                </Carousel>

                <button>Enquire Now</button>

            </section>
            
            <section>
                <Footer/>
            </section>
            <section>
                <Chatbox/>
            </section>

        </div>
    )
}