import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import './Product1.css'
// import P2sec1img1 from '../../Home img/Group 1000001600.webp'
// import P2sec2img1 from '../../Home img/image 206.webp'
// import P2sec2img2 from '../../Home img/image 204.webp'
// import P2sec2img3 from '../../Home img/image 205.webp'
// import P2sec3img1 from '../../Home img/14ffefe7-7887-4104-ab70-44f56e8ba8ef 1.png'
// import P2sec3img2 from '../../Home img/e9698755-7ad3-45f8-9e54-0e3191cd9154 1.png'
// import P2sec3img3 from '../../Home img/2f6b6423-5288-4aa9-87d6-5abc0a23600b 1.png'
// import P2sec3img4 from '../../Home img/8d75b418-779c-4a3f-bd41-6a7e343e874a 1.png'
// import P2sec4img1 from '../../Home img/Group 1000001550(2).webp'
// import P2sec4img2 from '../../Home img/Group 1000001591.webp'
// import P2sec4img3 from '../../Home img/Group 1000001590.webp'

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'


const P2sec1img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712119/Koushikmines%20images/Group_1000001600_vm0yjg.webp ",
P2sec2img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712142/Koushikmines%20images/image_206_x11khl.webp",
P2sec2img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712137/Koushikmines%20images/image_204_tfxpct.webp ",
P2sec2img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712139/Koushikmines%20images/image_205_pojxtb.webp",
P2sec3img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711900/Koushikmines%20images/14ffefe7-7887-4104-ab70-44f56e8ba8ef_1_bi0egf.png ",
P2sec3img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711920/Koushikmines%20images/e9698755-7ad3-45f8-9e54-0e3191cd9154_1_e3wkvy.png",
P2sec3img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711889/Koushikmines%20images/2f6b6423-5288-4aa9-87d6-5abc0a23600b_1_qqsexp.png",
P2sec3img4 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711897/Koushikmines%20images/8d75b418-779c-4a3f-bd41-6a7e343e874a_1_fjxbmd.png ",
P2sec4img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712087/Koushikmines%20images/Group_1000001550_2_yq4kmj.webp",
P2sec4img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712098/Koushikmines%20images/Group_1000001591_apyycc.webp ",
P2sec4img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712096/Koushikmines%20images/Group_1000001590_nmpwue.webp"



export default function Product2() {

    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div>
            <section>
                <Header/>
            </section>
            <section>
                <div className='Psec1'>
                    <h4> High Purity QUARTZ</h4>
                    <img src={P2sec1img1} alt='img' title=''/>
                </div>
            </section>

            <section className='Psec2'>
                <div className='Psec2-header'>
                    <h6> About Quartz </h6>
                    <p>Quartz,shimmering in its myriad hues, has captivated humanity for millennia. Ancient Egyptians revered it for its protective power, while the Romans whispered of its ability to kindle love. Today, its charm transcends the tangible. This ubiquitous mineral fuels our tech revolution, its silicon heart beating within every smartphone and computer. From dazzling countertops to intricate sculptures, it graces our homes with enduring beauty and unmatched strength. Quartz, a timeless treasure, whispers tales of progress and possibility, inviting us to imagine a future as dazzling as its crystal facets.</p>
                    <p>Beyond aesthetics, quartz's modern life is a whirlwind of technology. Its pure form fuels the delicate dance of electrons in semiconductors, the brains of your phone, computer, and countless other devices. In precision instruments, its piezoelectric properties translate vibrations into precise measurements, guiding lasers, microscopes, and telescopes to unlock the secrets of the universe. Even everyday heroes use quartz - in sandblasting to clean surfaces, in filters to purify water, and in road construction to ensure safe journeys. From the pocket-sized marvel in your hand to the towering skyscrapers piercing the sky, quartz silently weaves its magic into the fabric of our modern world.</p>
                    <div className='roundimg'>
                        <img src={P2sec2img1} alt='img' title=''/>
                        <img src={P2sec2img2} alt='img' title=''/>
                        <img src={P2sec2img3} alt='img' title=''/>
                    </div>

                    <h2>Uses of Quartz</h2>
                    <div className='Psecimg'>
                        <img src={P2sec3img1} alt='img' title=''/>
                        <h5> <strong>Semi-conductor:</strong>  Crystal substrate for precise timing in electronic circuits, oscillators, and sensors.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={P2sec3img2} alt='img' title=''/>
                        <h5><strong>Solar Panels:</strong> Quartz in solar panels enhances durability and efficiency by providing a protective and transparent surface for photovoltaic cells.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={P2sec3img3} alt='img' title=''/>
                        <h5><strong>Crucible  & Health care:</strong> Quartz used in lab equipment and crucibles due to its high resistance to heat and chemical stability.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={P2sec3img4} alt='img' title=''/>
                        <h5><strong>Glassmaking:</strong> Quartz is crucial in glass production, offering clarity and strength due to its high melting point and chemical stability.
                        </h5>
                    </div>

                </div>
            </section>

            <section className='Psec4'>
                <h3>Enquire</h3>

                <Carousel showThumbs={false} showStatus={false} showIndicators={true} className='Psec4-carousel' autoPlay={true} infiniteLoop={true} dynamicHeight={true}>
                    <div>
                        <img src={P2sec4img1} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={P2sec4img2} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={P2sec4img3} alt='img' title=''/>
                    </div>

                </Carousel>

                <button>Enquire Now</button>

            </section>

            <section>
                <Footer/>
            </section>

            <section>
                <Chatbox/>
            </section>
        
        </div>
    )
}