import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import './Product1.css'

// import P3sec1img1 from '../../Home img/Group 1000001599.webp'
// import P3sec2img1 from '../../Home img/Mica-Sheet.webp'
// import P3sec2img2 from '../../Home img/IMG-20240111-WA0000.webp'
// import P3sec2img3 from '../../Home img/IMG-20240111-WA0017.webp'
// import P3sec3img1 from '../../Home img/0e9feaa2-31f5-441e-8ff3-fb9abd5407b3 1.png'
// import P3sec3img2 from '../../Home img/2532df73-4c73-438d-bd1c-63ad0bada181 1.png'
// import P3sec3img3 from '../../Home img/786d539e-5c0d-4361-849b-ec0d63b1f6a4 1.png'
// import P3sec3img4 from '../../Home img/8cb1f828-a494-4d7a-80cd-bc3e17886708 1.png'
// import P3sec4img1 from '../../Home img/Group 1000001594.webp'
// import P3sec4img2 from '../../Home img/Group 1000001593.webp'
// import P3sec4img3 from '../../Home img/Group 1000001596.webp'

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'

const P3sec1img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712116/Koushikmines%20images/Group_1000001599_zvba9n.webp",
P3sec2img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712228/Koushikmines%20images/Mica-Sheet_up6izz.webp",
P3sec2img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712156/Koushikmines%20images/IMG-20240111-WA0000_tkegkh.webp ",
P3sec2img3 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712169/Koushikmines%20images/IMG-20240111-WA0017_hhvtqh.webp ",
P3sec3img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711839/Koushikmines%20images/0e9feaa2-31f5-441e-8ff3-fb9abd5407b3_1_cnolix.png ",
P3sec3img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711906/Koushikmines%20images/2532df73-4c73-438d-bd1c-63ad0bada181_1_ygdg1s.png",
P3sec3img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711904/Koushikmines%20images/786d539e-5c0d-4361-849b-ec0d63b1f6a4_1_yguwmc.png",
P3sec3img4 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711895/Koushikmines%20images/8cb1f828-a494-4d7a-80cd-bc3e17886708_1_xqy2lm.png ",
P3sec4img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712103/Koushikmines%20images/Group_1000001594_mxzuz4.webp",
P3sec4img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712101/Koushikmines%20images/Group_1000001593_kx2kuz.webp",
P3sec4img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712107/Koushikmines%20images/Group_1000001596_czajnn.webp"

export default function Product3() {

    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div>
            <section>
                <Header/>
            </section>
            <section>
                <div className='Psec1'>
                    <h4> MICA</h4>
                    <img src={P3sec1img1} alt='img' title=''/>
                </div>
            </section>

            <section className='Psec2'>
                <div className='Psec2-header'>
                    <h6>About Mica</h6>
                    <p>Mica, a mineral as versatile as it is vital, plays an understated yet crucial role in our daily lives. Hidden within the sparkle of cosmetics and the sheen of car paints, it adds both beauty and durability. In the realm of technology, mica's thermal resistance and insulating properties are indispensable, quietly embedded in electrical components and electronic devices. Its layered structure, capable of splitting into thin sheets, not only makes it a favorite in the cosmetic industry for its shimmer but also a trusted ally in industrial applications. From adding glitz to makeup to safeguarding electrical systems, mica blends functionality with a touch of glamour.</p>
                    <p>Geologically, mica is a storyteller, chronicling the Earth's formation through its presence in diverse rock types. It reveals the planet's dynamic processes, from intense heat and pressure deep underground to its emergence in visible rock formations. Mica's journey from the depths of the Earth to its use in everyday objects is a testament to nature's ingenuity. Its role extends beyond mere utility; it symbolizes the harmonious interplay between natural beauty and human innovation. In every product it enhances and every application it supports, mica weaves a narrative of resilience and adaptability, echoing the Earth's legacy and human creativity.</p>
                    <div className='roundimg'>
                        <img src={P3sec2img1} alt='img' title=''/>
                        <img src={P3sec2img2} alt='img' title=''/>
                        <img src={P3sec2img3} alt='img' title=''/>
                    </div>

                    <h2>Uses of Mica </h2>
                    <div className='Psecimg'>
                        <img src={P3sec3img1} alt='img' title=''/>
                        <h5> <strong>Electrical Insulation:</strong> Mica is used in electrical components for its high temperature resistance and dielectric strength, ideal for capacitors and insulating wires.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={P3sec3img2} alt='img' title=''/>
                        <h5><strong>Cosmetics:</strong>  In cosmetics, mica adds shimmer and pearlescence to products like eyeshadows and lipsticks.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={P3sec3img3} alt='img' title=''/>
                        <h5><strong>Paints and Coatings:</strong> Mica enhances paint durability, sheen, and color depth, and is used in protective coatings for its weather resistance.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={P3sec3img4} alt='img' title=''/>
                        <h5><strong>Plastics and Rubber:</strong> In plastics and rubber, mica serves as a filler to improve strength, stiffness, and heat resistance.
                        </h5>
                    </div>

                </div>
            </section>

            <section className='Psec4'>
                <h3>Enquire</h3>
                
                <Carousel showThumbs={false} showStatus={false} showIndicators={true} className='Psec4-carousel' autoPlay={true} infiniteLoop={true} dynamicHeight={true}>
                    <div>
                        <img src={P3sec4img1} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={P3sec4img2} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={P3sec4img3} alt='img' title=''/>
                    </div>
                    
                </Carousel>

                <button>Enquire Now</button>

            </section>

            <section>
                <Footer/>
            </section>
            <section>
                <Chatbox/>
            </section>

        </div>
    )
}