import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
// import Logo from '../Home img/Group 1000001452.webp';
// import hamburger from "../Home img/mingcute_menu-fill.svg";
// import mail from '../Home img/Icon.png';
// import phone from '../Home img/Icon(1).png';

const mail = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712125/Koushikmines%20images/Icon_zu4wnm.png",
    phone = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712127/Koushikmines%20images/Icon_1_dhcv3g.png",
    Logo = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712056/Koushikmines%20images/Group_1000001452_bgf6ji.webp"


const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const [showProductsDropdown, setShowProductsDropdown] = useState(false);
    const [showAboutusDropdown, setShowAboutusDropdown] = useState(false);
    const [showOtherDropdown, setShowOtherDropdown] = useState(false);



    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
        setShowProductsDropdown(false);
    };

    const handleProductsDropdown = () => {
        setShowProductsDropdown(!showProductsDropdown);
    };
    const handleAboutusDropdown = () => {
        setShowAboutusDropdown(!showAboutusDropdown);
    };
    const handleOtherDropdown = () => {
        setShowOtherDropdown(!showOtherDropdown);
    };

    const [isHomeDropdownOpen, setIsHomeDropdownOpen] = useState(false);
    const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
    const [isOthersDropdownOpen, setIsOthersDropdownOpen] = useState(false);


    const toggleHomeDropdown = () => {
        setIsHomeDropdownOpen(!isHomeDropdownOpen);
    };

    const toggleServicesDropdown = () => {
        setIsServicesDropdownOpen(!isServicesDropdownOpen);
    };
    const toggleOthersDropdown = () => {
        setIsOthersDropdownOpen(!isOthersDropdownOpen);
    }


    const handleDownload = () => {
        const pdfUrl = 'https://peteye-drive.s3.ap-south-1.amazonaws.com/KMM-+img/file.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'file.pdf';
        link.setAttribute('target', '_blank');
        link.setAttribute('download', ''); // Add the download attribute

        // Programmatically initiate the download
        link.click();
    };


    return (
        <nav className="navbar">
            <div className="container">

                <div className="logo">
                    <img src={Logo} alt='img' title='koushik mines' />
                </div>
                <input type="checkbox" name="check" id="check" onClick={handleShowNavbar} />
                <div class="hamburger-menu-container">
                    <div class="hamburger-menu" >
                        <div></div>
                    </div>
                </div>
                <div>

                </div>
                <div className={`nav-elements  ${showNavbar && "active"}`}>
                    <ul>
                        <li>
                            <Link to='/Home'>Home</Link>
                        </li>
                        <li>
                            <div className="dropdown">
                           
                                <a onClick={toggleHomeDropdown} className="dropbtn">
                                    Products &nbsp;<span className="dropdown-symbol">&#9662;</span>
                                </a>
                               
                                {isHomeDropdownOpen && (
                                    <div className="dropdown-content">
                                        <li><Link to='/MicaInfusedQuartz'> MICA Infused QUARTZ</Link></li>
                                        <li><Link to='/Quartz'>Quartz</Link></li>
                                        <li><Link to='/Mica'>Mica</Link></li>
                                        <li><Link to='/Feldspar'>Feldspar</Link></li>
                                    </div>
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="dropdown">
                                <a onClick={toggleServicesDropdown} className="dropbtn">
                                    About Us &nbsp;<span className="dropdown-symbol">&#9662;</span>
                                </a>
                                {isServicesDropdownOpen && (
                                    <div className="dropdown-content">
                                        <li><Link to='/Mine'> Our Mines</Link></li>
                                        <li><Link to='/About'>About Company</Link></li>
                                    </div>
                                )}
                            </div>
                        </li>
                        <li>
                            <div className="dropdown">
                                <a onClick={toggleOthersDropdown} className="dropbtn">
                                    Others &nbsp;<span className="dropdown-symbol">&#9662;</span>
                                </a>
                                {isOthersDropdownOpen && (
                                    <div className="dropdown-content">
                                        <li><Link to='/Article'> Blog</Link></li>
                                        <li><Link to='/Testreport'>Test Report</Link></li>
                                        <li><Link to='/Gallery'>Gallery</Link></li>
                                    </div>
                                )}
                            </div>
                        </li>
                        <li >
                            <button onClick={handleDownload}>
                                Download Brochure
                            </button>
                        </li>
                    </ul >
                </div >
                <div div className="right-content" >
                    <div className='mail'>
                        <img src={mail} alt="small_img" title='koushik mines' />
                        <a href="mailto:info@koushikmines.com">info@koushikmines.com</a>
                    </div>
                    <div className='mail'>
                        <img src={phone} alt="small_img" title='koushik mines' />
                        <a href="https://wa.me/+919246351219">+91 9246351219</a>
                    </div>
                </div >








            </div >
        </nav >
    );
};

export default Navbar;
