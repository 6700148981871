import { Carousel } from 'react-responsive-carousel';
import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useParams } from 'react-router-dom';

import './Testreport.css'


// import test1 from '../../Home img/test4.webp'
// import test2 from '../../Home img/test2.webp'
// import test3 from '../../Home img/test1.webp'
// import test4 from '../../Home img/test3.webp'
// import test5 from '../../Home img/test6.webp'
// import test6 from '../../Home img/test7.webp'
// import test7 from '../../Home img/test5.webp'

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'


const test1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712256/Koushikmines%20images/test4_e5cfvy.webp ",
test2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712251/Koushikmines%20images/test2_tpdrch.webp ",
test3 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712248/Koushikmines%20images/test1_ipjenv.webp ",
test4 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712253/Koushikmines%20images/test3_h1b2h5.webp ",
test5 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712261/Koushikmines%20images/test6_uemndz.webp",
test6 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712263/Koushikmines%20images/test7_n6aur0.webp ",
test7 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712258/Koushikmines%20images/test5_nstmmg.webp "


const MyCarousel = () => {
  const totalSlides = 7;
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevious = () => {
    setCurrentSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : totalSlides - 1));
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) => (prevSlide < totalSlides - 1 ? prevSlide + 1 : 0));
  };


  const { section } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  
  return (
    <div>
      <section>
        <Header/>
      </section>

      <div className='report'>
        <h4>Test Report</h4>
        <Carousel showThumbs={false} selectedItem={currentSlide}
          onChange={(index) => setCurrentSlide(index)}
          showStatus={false} showArrows={false} showIndicators={false} className='carousel' autoPlay={true} infiniteLoop={true} dynamicHeight={true}>
          <div>
            <img src={test1}  alt='img' title=''/>
          </div>
          <div>
            <img src={test2}  alt='img' title=''/>
          </div>
          <div>
            <img src={test3}  alt='img' title=''/>
          </div>
          <div>
            <img src={test4}  alt='img' title=''/>
          </div>
          <div>
            <img src={test5}  alt='img' title=''/>
          </div>
          <div>
            <img src={test6}  alt='img' title=''/>
          </div>
          <div>
            <img src={test7}  alt='img' title=''/>
          </div>
        </Carousel>
        <div className='btn'>
          <button onClick={handlePrevious}>Prev</button>
          <button onClick={handleNext}>Next</button>
        </div>
      </div>

      <section>
        <Footer/>
      </section>

      <section>
                <Chatbox/>
            </section>
    </div>
  );
};

export default MyCarousel;