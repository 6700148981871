import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './Mine.css'

// import Msec1img1 from '../../Home img/mine1img.webp'
// import Msec2img1 from '../../Home img/Intersect(1).webp'
// import Msec2img2 from '../../Home img/IMG-20240108-WA0011.webp'
// import Msec2img3 from '../../Home img/IMG-20240108-WA0014.webp'
// import Msec3img1 from '../../Home img/Frame 34557(1).webp'
// import Msec3img2 from '../../Home img/Frame 34557(2).webp'
// import Msec3img3 from '../../Home img/Frame 34557(3).webp'
// import Msec3img4 from '../../Home img/Frame 34557(4).webp'
// import Msec3img5 from '../../Home img/Frame 34557(5).webp'
// import Msec3img6 from '../../Home img/Frame 34557(6).webp'
// import Msec3img7 from '../../Home img/Frame 34557(7).webp'
// import minebigimg from '../../Home img/MINES bigimg.webp'

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'



const  Msec1img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712234/Koushikmines%20images/mine1img_hemcyh.webp ",
Msec2img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712209/Koushikmines%20images/Intersect_1_h4x3q0.webp ",
Msec2img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712144/Koushikmines%20images/IMG-20240108-WA0011_l9lvze.webp ",
Msec2img3 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712147/Koushikmines%20images/IMG-20240108-WA0014_ug1om2.webp ",
Msec3img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711927/Koushikmines%20images/Frame_34557_1_sf5dkg.webp ",
Msec3img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711929/Koushikmines%20images/Frame_34557_2_jy1ahd.webp ",
Msec3img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711932/Koushikmines%20images/Frame_34557_3_nost37.webp",
Msec3img4 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711934/Koushikmines%20images/Frame_34557_4_eoswxd.webp ",
Msec3img5 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711936/Koushikmines%20images/Frame_34557_5_cjivso.webp ",
Msec3img6 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711980/Koushikmines%20images/Frame_34557_6_eksrto.webp",
Msec3img7 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711983/Koushikmines%20images/Frame_34557_7_vrkjqq.webp",
minebigimg = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712237/Koushikmines%20images/MINES_bigimg_ekjwjs.webp"

export default function Mine() {
    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);


    return (
        <div>
            <section>
                <Header/>
            </section>
            <section className='Msec1'>
                <div className='imgsec1'>
                    <img src={Msec1img1} alt='img1' title=''/>
                </div>
            </section>

            <section className='Msec2'>
                <div className='Msec2-header'>
                    <h2>Mines</h2>
                    <p>Immerse yourself in the heart of extraction, where the earth's rich tapestry unfolds. Our mines are a testament to nature's hidden splendor, revealing minerals that have shaped civilizations. Here, in the deep cradle of the earth, we harness the treasures that lie beneath, with the utmost respect for the land that yields them. Experience the raw beauty and the meticulous craft of mining, where every gem tells a story of time, pressure, and transformation.</p>
                    <h3>Our Mining Area</h3>
                    <div className='roundimg'>
                        <img src={Msec2img1} alt='img' title=''/>
                        <img src={Msec2img2} alt='img' title=''/>
                        <img src={Msec2img3} alt='img' title=''/>
                    </div>
                    <p>Immerse yourself in the heart of extraction, where the earth's rich tapestry unfolds. Our mines are a testament to nature's hidden splendor, revealing minerals that have shaped civilizations. Here, in the deep cradle of the earth, we harness the treasures that lie beneath, with the utmost respect for the land that yields them. Experience the raw beauty and the meticulous craft of mining, where every gem tells a story of time, pressure, and transformation.</p>

                    <h2>Our Work Process</h2>
                    <div className='secimg'>
                        <img src={Msec3img1} alt='img' title=''/>
                        <h5>Decades of expertise position us as a trusted name in the field.
                            Our commitment to excellence is evident in every operation,
                            ensuring top-notch quality and unwavering integrity.
                        </h5>
                    </div>
                    <div className='secimg'>
                        <img src={Msec3img2} alt='img' title=''/>
                        <h5>We begin by meticulously identifying and securing mines with
                            high-grade quartz deposits, sourcing the finest raw materials
                            for exceptional quality.
                        </h5>
                    </div>
                    <div className='secimg'>
                        <img src={Msec3img3} alt='img' title=''/>
                        <h5>Our thorough cleaning process removes impurities, achieving
                            desired purity that meets stringent customer requirements.
                        </h5>
                    </div>
                    <div className='secimg'>
                        <img src={Msec3img4} alt='img' title=''/>
                        <h5>Meticulous separation categorizes minerals based on purity,
                            guaranteeing only the finest products reach customers.
                        </h5>
                    </div>
                    <div className='secimg'>
                        <img src={Msec3img5} alt='img' title=''/>
                        <h5>Rigorous quality control adheres to international standards,
                            exceeding the highest benchmarks.
                        </h5>
                    </div>
                    <div className='secimg'>
                        <img src={Msec3img6} alt='img' title=''/>
                        <h5>Careful encapsulation safeguards products during transit and
                            storage, ensuring impeccable arrival.
                        </h5>
                    </div>
                    <div className='secimg'>
                        <img src={Msec3img7} alt='img' title=''/>
                        <h5>With a presence in India, Japan, China, USA, South Korea,
                            and UAE, we empower seamless worldwide trade.
                            We stand firm in our mission to lead the industry, delivering
                            unparalleled quality and experience.
                        </h5>
                    </div>
                </div>
            </section>

            <section>
                <div className='Msec4'>
                    <h6>  Our Mine Photos</h6>
                    <img src={minebigimg} alt='img' title=''/>
                </div>
            </section>

            <section>
                <Footer/>
            </section>

            <section>
                <Chatbox/>
            </section>
        </div>
    )
}
