import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import './Product1.css'
// import Psec1img1 from '../../Home img/Group 1000001522.webp'
// import Psec2img1 from '../../Home img/IMG-20240111-WA0034.webp'
// import Psec2img2 from '../../Home img/IMG-20240111-WA0033.webp'
// import Psec2img3 from '../../Home img/IMG-20240108-WA0029 2.webp'
// import Psec3img1 from '../../Home img/14ffefe7-7887-4104-ab70-44f56e8ba8ef 1.png'
// import Psec3img2 from '../../Home img/e9698755-7ad3-45f8-9e54-0e3191cd9154 1.png'
// import Psec3img3 from '../../Home img/2f6b6423-5288-4aa9-87d6-5abc0a23600b 1.png'
// import Psec3img4 from '../../Home img/016906d3-324d-401f-920b-b92d4a58992b 1.png'
// import Psec4img1 from '../../Home img/Group 1000001558(1).webp'
// import Psec4img2 from '../../Home img/Group 1000001595.webp'
// import Psec4img3 from '../../Home img/Group 1000001550(1).webp'

import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'

const Psec1img1 = " https://peteye-drive.s3.ap-south-1.amazonaws.com/KMM-+img/kmm1.png",
Psec2img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712177/Koushikmines%20images/IMG-20240111-WA0034_qmtlci.webp ",
Psec2img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712174/Koushikmines%20images/IMG-20240111-WA0033_f35t1e.webp ",
Psec2img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712154/Koushikmines%20images/IMG-20240108-WA0029_2_wuofsf.webp",
Psec3img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711900/Koushikmines%20images/14ffefe7-7887-4104-ab70-44f56e8ba8ef_1_bi0egf.png",
Psec3img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711920/Koushikmines%20images/e9698755-7ad3-45f8-9e54-0e3191cd9154_1_e3wkvy.png ",
Psec3img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711889/Koushikmines%20images/2f6b6423-5288-4aa9-87d6-5abc0a23600b_1_qqsexp.png",
Psec3img4 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711909/Koushikmines%20images/016906d3-324d-401f-920b-b92d4a58992b_1_rejr7w.png",
Psec4img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712089/Koushikmines%20images/Group_1000001558_1_qyakrl.webp",
Psec4img2 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712105/Koushikmines%20images/Group_1000001595_njla5e.webp ",
Psec4img3 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712084/Koushikmines%20images/Group_1000001550_1_s5ut3h.webp"

export default function Product1() {

    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    return (
        <div>
            <section>
                <Header/>
            </section>
            <section>
                <div className='Psec1'>
                    <h4> MICA Infused QUARTZ</h4>
                    <img src={Psec1img1} alt='img' title=''/>
                </div>
            </section>

            <section className='Psec2'>
                <div className='Psec2-header'>
                    <h6>About Infused <br/> Quartz with Mica</h6>
                    <p>Infused mica with quartz, a mineral as intricate as nature's own design, plays a vital role in various facets of daily life. It's found in elegant countertops and decorative tiles, adding luster and strength to architectural elements. Its diverse range, from the subtle shimmer of quartz-infused mica to the striking sparkle when illuminated, enchants interior designers and homeowners alike. In the realm of construction and design, this composite material is indispensable for creating durable and aesthetically pleasing surfaces, offering a unique blend of beauty and resilience.</p>
                    <p>This mineral composition also tells a story of geological artistry, present in metamorphic and igneous formations, showcasing the natural fusion of mica and quartz under Earth's dynamic conditions. Its occurrence in such rocks speaks to the profound processes that shape our planet. Infused mica with quartz is more than just a component of the Earth's crust; it's a testament to the fusion of elegance and endurance, a symbol of the harmony between aesthetics and practicality that drives human innovation in design and Technology. In every shimmering countertop and radiant tile, this composite whispers a narrative of Earth's transformative power and our quest for blending functionality with beauty, urging us to delve deeper into the marvels of both our planet and our creativity.</p>
                    <div className='roundimg'>
                        <img src={Psec2img1} alt='img' title=''/>
                        <img src={Psec2img2} alt='img' title='' />
                        <img src={Psec2img3} alt='img' title=''/>
                    </div>

                    <h2>Uses of Mica Infused Quartz</h2>
                    <div className='Psecimg'>
                        <img src={Psec3img1} alt='img' title=''/>
                        <h5> <strong>Semi-conductor:</strong> Mica-infused quartz enhances semiconductor performance, improving insulation and stability in electronic components and devices.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={Psec3img2} alt='img' title=''/>
                        <h5><strong>Solar Panels:</strong> Mica-infused quartz enhances solar panels by improving light absorption and durability, boosting their overall efficiency.
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={Psec3img3} alt='img' title=''/>
                        <h5><strong>Crucible:</strong> Mica-infused quartz crucibles enhance thermal stability, ideal for high-temperature materials processing, melting and this are also used in Semi conductor Vapor
                        </h5>
                    </div>
                    <div className='Psecimg'>
                        <img src={Psec3img4} alt='img' title=''/>
                        <h5><strong>Electronics:</strong> Processing of silicon, metals, and rare earth elements produces smartphones, computers, televisions, and other electronic devices.
                        </h5>
                    </div>

                </div>
            </section>

            <section className='Psec4'>
                <h3>Enquire</h3>
                
                <Carousel showThumbs={false} showStatus={false} showIndicators={true} className='Psec4-carousel' autoPlay={true} infiniteLoop={true} dynamicHeight={true}>
                   
                    <div>
                        <img src={Psec4img2} alt='img' title=''/>
                    </div>
                    <div>
                        <img src={Psec4img3} alt='img' title=''/>
                    </div>
                    
                </Carousel>

                <button>Enquire Now</button>

            </section>
            <section>
                <Footer/>
            </section>
            <section>
                <Chatbox/>
            </section>

        </div>
    )
}