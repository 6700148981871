import React, { useEffect, useState, useRef } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Home.css'
import { useParams } from 'react-router-dom';



// import c1img from '../../Home img/carousel1.webp'
// import c2img from '../../Home img/carousel2.webp'
// import c3img from '../../Home img/carousel3.webp'
// import sec2img1 from '../../Home img/Group 1000001607.png'
// import sec3img1 from '../../Home img/IMG-20240111-WA0034(1).webp'
// import sec3img2 from '../../Home img/IMG-20240108-WA0029 1(1).webp'
// import line from '../../Home img/Line 12.webp'
// import sec4img1 from '../../Home img/Group 1000001546.webp'
// import sec5img1 from '../../Home img/IMG-20240111-WA0009.webp'
// import sec5img2 from '../../Home img/IMG-20240111-WA0027.webp'
// import sec6img1 from '../../Home img/Group 1000001548.webp'
// import sec7img1 from '../../Home img/IMG-20240111-WA0000mica.webp'
// import sec7img2 from '../../Home img/Mica-Sheetmica.webp'
// import sec8img1 from '../../Home img/Group 1000001584.webp'
// import sec9img1 from '../../Home img/IMG-20240118-WA0010.webp'
// import sec9img2 from '../../Home img/IMG-20240116-WA0031feldspar.webp'
// import mapimg from '../../Home img/Frame 34574(1).webp'


import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import Chatbox from '../../ChatBox/Chatbox'

const c1img = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711913/Koushikmines%20images/carousel1_phfhcy.webp ",
c2img = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711916/Koushikmines%20images/carousel2_wygulu.webp",
c3img = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714711918/Koushikmines%20images/carousel3_csgsjj.webp ",
c4img = "https://peteye-drive.s3.ap-south-1.amazonaws.com/KMM-+img/Mine+video1.gif",
sec2img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712121/Koushikmines%20images/Group_1000001607_bzdtbr.png  ",
sec3img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712179/Koushikmines%20images/IMG-20240111-WA0034_1_gj2dzi.webp",
sec3img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712152/Koushikmines%20images/IMG-20240108-WA0029_1_1_kwx96b.webp",
line = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712217/Koushikmines%20images/Line_12_r1zvsi.webp",
sec4img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712077/Koushikmines%20images/Group_1000001546_ixrwfz.webp ",
sec5img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712162/Koushikmines%20images/IMG-20240111-WA0009_s7pagz.webp ",
sec5img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712171/Koushikmines%20images/IMG-20240111-WA0027_ddsne4.webp",
sec6img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712082/Koushikmines%20images/Group_1000001548-1_iljqdq.webp ",
sec7img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712159/Koushikmines%20images/IMG-20240111-WA0000mica_gxyfhw.webp",
sec7img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712231/Koushikmines%20images/Mica-Sheetmica_covatb.webp",
sec8img1 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712091/Koushikmines%20images/Group_1000001584_xn6u1t.webp",
sec9img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712190/Koushikmines%20images/IMG-20240118-WA0010_echjfq.webp ",
sec9img2 = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712187/Koushikmines%20images/IMG-20240116-WA0031feldspar_yrdlek.webp",
mapimg = " https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712030/Koushikmines%20images/Frame_34574_1_nmqygq.webp"

const ChatboxComponent = () => {

    const { section } = useParams();

    useEffect(() => {
      window.scrollTo(0, 0); 
    }, []);

    
    return (
        <div>
            <section>
                <Header />
            </section>
            <section className='sec1'>
                <Carousel showThumbs={false} showStatus={false} showIndicators={true} className='sec1-carousel' autoPlay={true} infiniteLoop={true} dynamicHeight={true}>
                    <div>
                        <img src={c1img} alt='img3' title=''/>
                    </div>
                    <div>
                        <img src={c2img} alt='img3' title=''/>
                    </div>
                    <div>
                        <img src={c3img} alt='img3' title=''/>
                    </div>
                    <div>
                        <img src={c4img} alt='img3' title=''/>
                    </div>
                </Carousel>

            </section>

            {/* product 1 */}
            <section className='sec2'>
                <div className='sec2-header'>
                    <h1> Infused Mica With Quartz</h1>
                    <p>Discover premium Infused Mica with Quartz: a testament to durability and shimmering beauty. Where the reflective allure of mica meets the robustness of quartz</p>
                    <button className='btn-primary'>Know More Details</button>
                </div>
                <div className='middleimg'>
                    <img src={sec2img1} alt='img1' title=''/>
                </div>

            </section>

            <section className='sec3'>
                <div className='sec3-left'>
                    <img src={sec3img1} alt='img2' title=''/>
                </div>
                <div className='sec3-right'>
                    <p>Use Cases</p>
                    <h2>Why Choose Infused Mica with Quartz</h2>
                    <div className='rightline'>
                        <h5>Quartz</h5>
                        <h3> <img src={line} alt='img3' title=''/> </h3>
                    </div>
                    <p>Experience the elegance and resilience of our Infused Mica with Quartz. Crafted with the strength of quartz, used in everything from glassmaking to high-tech applications, and the radiant sheen of mica, our product promises long-lasting beauty and versatility. Elevate your space with this adaptable, sophisticated material, perfect for anyone looking to blend functionality with a touch of luxury.</p>
                    <button className='btn-primary'>Know More Details</button>
                    <br />
                    <img src={sec3img2} alt='img3' />
                </div>

            </section>
            {/* product 2 */}


            <section className='sec2'>
                <div className='sec2-header'>
                    <h2>  Quartz</h2>
                    <p>Quartz is renowned for its exceptional hardness, resistance to acid and erosion, and electrical properties, making it indispensable for precision instruments, glassmaking, and as an industrial abrasive</p>
                    <button className='btn-primary'>Know More Details</button>
                </div>
                <div className='middleimg'>
                    <img src={sec4img1} alt='img1' title=''/>
                </div>

            </section>

            <section className='sec3'>
                <div className='sec3-left'>
                    <img src={sec5img1} alt='img2' title='' />
                </div>
                <div className='sec3-right'>
                    <p>Use Cases</p>
                    <h2>Why Choose Quartz</h2>
                    <div className='rightline'>
                        <h5>Quartz</h5>
                        <h3> <img src={line} alt='img3' title=''/> </h3>
                    </div>
                    <p>Quartz is chosen for its durability, chemical inertness, and its ability to withstand high temperatures without deformation. It's also valued for its aesthetic appeal, providing a clear, glass-like appearance that is versatile for various applications in industries like electronics, construction, and jewelry.</p>
                    <button className='btn-primary'>Know More Details</button>
                    <br />
                    <img src={sec5img2} alt='img3' title='' />
                </div>

                {/* product 3 */}

            </section><section className='sec2'>
                <div className='sec2-header'>
                    <h2>Mica</h2>
                    <p>Mica's specialty lies in its electrical insulation and heat resistance, along with its ability to be split into transparent, flexible sheets, making it invaluable in electronic, automotive, and cosmetic industries.</p>
                    <button className='btn-primary'>Know More Details</button>
                </div>
                <div className='middleimg'>
                    <img src={sec6img1} alt='img1' title=''/>
                </div>

            </section>

            <section className='sec3'>
                <div className='sec3-left'>
                    <img src={sec7img1} alt='img2' title=''/>
                </div>
                <div className='sec3-right'>
                    <p>Use Cases</p>
                    <h2>Why Choose Mica </h2>
                    <div className='rightline'>
                        <h5>Quartz</h5>
                        <h3> <img src={line}  alt='img3' title=''/> </h3>
                    </div>
                    <p>Mica is chosen for its unique combination of flexibility, heat resistance, and electrical insulation properties, making it ideal for use in electronic equipment, thermal insulation, and as a substrate in various industrial and cosmetic applications. Its transparency and ability to be cleaved into thin sheets are valued for both industrial uses and decorative purposes. Mica's reflective and refractive qualities also make it a key component in paint and makeup products, enhancing luster and providing depth.</p>
                    <button className='btn-primary'>Know More Details</button>
                    <br />
                    <img src={sec7img2} alt='img3' title=''/>
                </div>
            </section>

            {/* product 4 */}
            <section className='sec2'>
                <div className='sec2-header'>
                    <h2> Premium Grade FELDSPAR</h2>
                    <p>Feldspar, a silicate mineral, is formed from silicon-oxygen bonds, incorporating elements like aluminum, sodium, and potassium, which diversify its variants and uses.</p>
                    <button className='btn-primary'>Know More Details</button>
                </div>
                <div className='middleimg'>
                    <img src={sec8img1} alt='img1' title=''/>
                </div>

            </section>

            <section className='sec3'>
                <div className='sec3-left'>
                    <img src={sec9img1} alt='img2' title=''/>
                </div>
                <div className='sec3-right'>
                    <p>Use Cases</p>
                    <h2>Why Choose Feldspar</h2>
                    <div className='rightline'>
                        <h5>Quartz</h5>
                        <h3> <img src={line} alt='img3' title=''/> </h3>
                    </div>
                    <p>Potassium feldspar is a valuable mineral in the ceramic industry due to its ability to act as a white bright melt, which is essential for achieving the desired whiteness and brightness in ceramic applications. Its utilization as a flux agent is beneficial because of its high viscosity, contributing to the strength and durability of ceramic products.</p>
                    <button className='btn-primary'>Know More Details</button>
                    <br />
                    <img src={sec9img2} alt='img3' title='' />
                </div>

            </section>
            <section>
                <div className="image-container">
                    <img src={mapimg} alt="Your Image" className="image"  title=''/>
                    <div className="overlay-text">
                        <p>Find us at</p>
                        <p>Survey No. 326/1 , Orupalle Village, Turimerla Post, Sydapuram Mandal, Nellore District, Andhra Pradesh - 524469, India. </p>
                        <button><a href='https://maps.app.goo.gl/kLwY5Kw1j6aJDbLn9'> On Map</a></button>
                    </div>
                </div>
            </section>
            <section>
                <Footer />
            </section>

            

            <section>
                <Chatbox/>
            </section>
        </div>
    )

}

export default ChatboxComponent;
