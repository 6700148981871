import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
// import Fsec1img1 from '../Home img/Group 1000001452.webp';
// import Ffb from '../Home img/Group 1145.png';
// import Ftwt from '../Home img/Group 1146.png';
// import Fins from '../Home img/Layer 1.png';
// import mail from '../Home img/Icon.png';

import './Footer.css';


const Ffb = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712048/Koushikmines%20images/Group_1145_wxnrda.png",
    Ftwt = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712051/Koushikmines%20images/Group_1146_v9u95x.png ",
    Fins = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712215/Koushikmines%20images/Layer_1_syaa1y.png ",
    mail = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712125/Koushikmines%20images/Icon_zu4wnm.png",
    phone = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712127/Koushikmines%20images/Icon_1_dhcv3g.png",
    Fsec1img1 = "https://res.cloudinary.com/dlmg2jocp/image/upload/v1714712056/Koushikmines%20images/Group_1000001452_bgf6ji.webp"


export default function Footer() {

    return (
        <div className='footerpart'>
            <section className='footer'>
                <div className='Fsec1'>
                    <img className='Fsec1img' src={Fsec1img1} alt='img' title='' />
                    <p>All so you can find on</p>
                    <div className='social'>
                        <Link to="https://www.facebook.com/koushikmines.minerals/"><img className='socialimg' src={Ffb} alt='fb' title='' /></Link>
                        <Link to="https://twitter.com/KoushikMinerals"><img className='socialimg' src={Ftwt} alt='twt' title='' /></Link>
                        <Link to="https://in.linkedin.com/company/koushik-mines-minerals"><img className='socialimg' src={Fins} alt='ins' title='' /></Link>
                    </div>
                </div>

                <div className='Fsec2'>
                    <h2>Resources</h2>
                    <Link to='/Home'>Home</Link>
                    <Link to='/Mine'>Mines</Link>
                    <Link to='/Product1'>Products</Link>
                    <Link to='/About'>About us</Link>
                    <a href="https://wa.me/+919246351219">Contact us</a>
                </div>

                <div className='Fsec3'>
                    <h2>Company</h2>
                    <Link to='#'>Partnership</Link>
                    <Link to='#'>Terms of use</Link>
                    <Link to='#'>Privacy</Link>
                </div>

                <div className='Fsec4'>
                    <div className='Fsec4-mail'>
                    <img src={mail} alt='img' title='' />
                    <a href="mailto:info@koushikmines.com">info@koushikmines.com</a>
                    </div>
                    <div className='Fsec4-mail'>
                    <img src={phone} alt="small_img" title='' />
                    <a href="https://wa.me/+919246577788">+91 9246577788</a>
                    </div>
                </div>
            </section>

            <section className='Fpart2'>
                <hr className='Fline' />
                <p className='Fp'>Copyrights © Koushik Mines and Minerals All rights Reserved.</p>
            </section>
        </div>
    );
}
